@font-face {
  font-family: "Poppins-Light";
  src: url(./fonts/Poppins-Light.ttf);
}
@font-face {
  font-family: "Poppins-Regular";
  src: url(./fonts/Poppins-Regular.ttf);
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: url(./fonts/Poppins-SemiBold.ttf);
}
@font-face {
  font-family: "Poppins-Bold";
  src: url(./fonts/Poppins-Bold.ttf);
}

@font-face {
  font-family: "OpenSans-Regular";
  src: url(./fonts/OpenSans-Regular.ttf);
}
@font-face {
  font-family: "OpenSans-SemiBold";
  src: url(./fonts/OpenSans-SemiBold.ttf);
}

body {
  font-family: "Poppins-Regular" !important;
  font-size: 24px;
  color: #f5f5f5;
}
@media (max-width: 768px) {
  body {
    font-size: 20px;
  }
}

/*Weight 300 */
.peso-light {
  font-family: "Poppins-Light";
}
/*Weight 400 */
.peso-regular {
  font-family: "Poppins-Regular";
}
/*Weight 600 */
.peso-semibold {
  font-family: "Poppins-SemiBold";
}
/*Weight 700 */
.peso-bold {
  font-family: "Poppins-Bold";
}

/*Weight 400 */
.peso-regular2 {
  font-family: "OpenSans-Regular";
}
/*Weight 600 */
.peso-semibold2 {
  font-family: "OpenSans-SemiBold";
}

.tamaño-gigante {
  font-size: 88px;
}
h1 {
  font-size: 88px !important;
}

.tamaño-mas-grande {
  font-size: 72px;
}
h2 {
  font-size: 72px !important;
}

.tamaño-grande {
  font-size: 40px;
}
h3 {
  font-size: 40px !important;
}

.tamaño-medio {
  font-size: 24px;
}

p {
  font-size: 24px !important;
}

.tamaño-intermedio{
  font-size: 21px !important;
}

.tamaño-chico {
  font-size: 19px !important;
}
.tamaño-mas-chico {
  font-size: 16px !important;
}

@media (max-width: 900px) {
  .tamaño-mas-grande {
    font-size: 48px;
  }
  h2 {
    font-size: 48px !important;
  }

  .tamaño-grande {
    font-size: 32px;
  }
  h3 {
    font-size: 32px !important;
  }

  .tamaño-medio {
    font-size: 20px;
  }

  .tamaño-intermedio{
    font-size: 20px !important;
  }

  p {
    font-size: 20px !important;
  }

  .tamaño-chico {
    font-size: 16px !important;
  }
  .tamaño-mas-chico {
    font-size: 15px !important;
  }
}

.color-negro {
  color: #0b0e11;
}

.color-negro-fondo {
  background-color: #0b0e11;
}

.color-dorado {
  background: -webkit-linear-gradient(354.32deg, #b28405 4.22%, #f2ca5b 96.07%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* background-clip: text; */
  /* text-fill-color: transparent; */
}

.color-dorado-fondo {
  background: linear-gradient(275.16deg, #bb8e11 5%, #f2ca5b 49.17%, #b28405 94.82%);
}

.color-naranja {
  color: #b28405;
}

.color-blanco {
  color: #f5f5f5;
}

.color-blanco-fondo {
  background-color: #f5f5f5;
}

.color-gris-fondo {
  background-color: #ebeced;
}

.color-gris {
  color: #abb2bb;
}

.color-gris-oscuro {
  color: #838994;
}

.sin-sub {
  text-decoration: none;
}

.espaciado {
  letter-spacing: 7px;
}
.interlineado {
  line-height: 24px;
}
.interlineado2 {
  line-height: 52.8px;
}

/*Banner home*/
.banner-home {
  background-image: url(./assets/img/banner.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 80vh;
}

/*Btn Contacto*/
.boton-contacto {
  width: 143px;
  height: 57px;
  background: linear-gradient(275.16deg, #bb8e11 5%, #f2ca5b 49.17%, #b28405 94.82%);
  border-radius: 12px !important;
  color: #0b0e11 !important;
}
@media (max-width: 768px) {
  .boton-contacto {
    height: 49px;
  }
}

.posi-boton {
  display: flex;
  justify-content: center;
  margin-top: -320px !important;
  margin-bottom: 200px;
}

@media (max-width: 1440px) {
  .posi-boton {
    margin-top: -20vh !important;
    margin-bottom: 30px;
  }
}

@media (max-width: 1024px) {
  .posi-boton {
    margin-top: -140px !important;
    margin-bottom: 30px;
  }
}

@media (max-width: 455px) {
  .posi-boton {
    margin-top: -200px !important;
    margin-bottom: 90px;
  }
}

/*PROXIMOS EVENTOS*/
.imagen-carousel {
  height: 662px;
}

@media (max-width: 1024px) {
  .imagen-carousel {
    height: 459px;
  }
}

@media (max-width: 992px) {
  .imagen-carousel {
    height: 1024px;

  }
}

@media (max-width: 500px) {
  .imagen-carousel {
    height: 712px;

  }
}

.carousel-control-prev {
  justify-content: flex-start !important;
}

.carousel-control-next {
  justify-content: flex-end !important;
}

/* @media (max-width: 990px) {
  .carousel-control-next-icon {
    margin-top: -350px;
  }
  .carousel-control-prev-icon {
    margin-top: -350px;
  }
} */

/*boton-contactar-mc-incribirme*/
.boton-contactar-mc-incribirme {
  width: 184px;
  height: 57px;
  background: linear-gradient(275.16deg, #bb8e11 5%, #f2ca5b 49.17%, #b28405 94.82%);
  border-radius: 12px !important;
  color: #0b0e11 !important;
}

/*boton-contactar-mc-wsp*/
.boton-contactar-mc-wsp {
  width: 184px;
  height: 57px;
  background: linear-gradient(180deg, #57d163 0%, #23b33a 100%);
  border-radius: 12px !important;
  color: #0b0e11 !important;
}

/*Btn wsp*/
.boton-contactar-wsp {
  width: 184px;
  height: 57px;
  background: linear-gradient(180deg, #57d163 0%, #23b33a 100%);
  border-radius: 12px !important;
  color: #0b0e11 !important;
}

@media (max-width: 768px) {
  .boton-contactar-wsp {
    width: 145.5px;
  }
}
/* btn ver mas */
.boton-ver-mas {
  width: 184px;
  height: 57px;
  border-radius: 12px !important;
  background-color: #0b0e11 !important;
  color: #b28405 !important;
  border-color: #b28405 !important;
}

@media (max-width: 500px) {
  .boton-ver-mas {
    width: 145.5px;
  }
}

/* Nuestros Cursos */

.card-curso {
  border: 0 !important;
  width: 270px;
  height: 577px;
  border-radius: 24px !important;
  box-shadow: 10px 40px 50px rgba(229, 233, 246, 0.4);
}
@media (max-width: 500px) {
  .card-curso {
    width: 327px;
    height: 505px;
  }
}

.image-card {
  border-top-left-radius: 24px !important;
  border-top-right-radius: 24px !important;
  height: 100%!important;
}

/*Btn Proxima*/
.boton-proxima {
  width: 193px;
  height: 49px;
  background: linear-gradient(275.16deg, #bb8e11 5%, #f2ca5b 49.17%, #b28405 94.82%);
  border-radius: 12px !important;
  color: #0b0e11 !important;
}

.card-tips {
  border: 1px solid #838994 !important;
  width: 282px !important;
  height: 190px !important;
  border-radius: 20px !important;
}
@media (max-width: 500px) {
  .card-tips {
    width: 180px !important;
    height: 153px !important;
  }
}
.imagen-model {
  width: 100%;
  /* max-width:538px !important; */
  height: auto;
  border-radius: 24px !important;
}

@media (max-width: 500px) {
  .imagen-model {
    width: 100%;
    height: auto;
  }
}

/* ENTERATE */

.fondo-bicolor {
  height: 600px !important;
}
.primera {
  height: 300px !important;
}
.segunda {
  height: 300px !important;
}
.card-enterate {
  margin-top: -194px;
  border: 0 !important;
  width: 878px !important;
  height: 393px !important;
  border-radius: 24px !important;
  box-shadow: 10px 40px 50px rgba(229, 233, 246, 0.4);
}
.text-enterate {
  width: 480px !important;
  height: 57px !important;
  background: rgba(0, 0, 0, 0.04) !important;
  border: 1px solid #e6e7e8 !important;
  border-radius: 16px !important;
}
.boton-enviar {
  width: 119px;
  height: 57px;
  background: linear-gradient(275.16deg, #bb8e11 5%, #f2ca5b 49.17%, #b28405 94.82%);
  border-radius: 12px !important;
  color: #0b0e11 !important;
}
@media (max-width: 900px) {
  .card-enterate .card-body {
    padding: 0;
  }
  .card-enterate {
    margin-top: -246px;
    width: 327px !important;
    height: 492px !important;
  }
  .text-enterate {
    width: 287px !important;
    border-radius: 16px !important;
  }
  .boton-enviar {
    width: 287px !important;
    border-radius: 16px !important;
  }
}

/* Novedades */

.card-novedades {
  border: 0 !important;
  width: 360px !important;
  height: 620px !important;
  border-radius: 24px !important;
  box-shadow: 10px 40px 50px rgba(229, 233, 246, 0.4);
}
.card-novedades .card-title {
  height: 60px;
}

.card-novedades .card-text {
  height: 90px;
}

.card-novedades pre {
  overflow: hidden;
  white-space: pre-wrap;
}
.card-novedades .color-negro:hover{
  color: #b28405;
}
.card-novedades .color-naranja:hover{
  color: #b2840564;
}

@media (max-width: 900px) {
  .card-novedades {
    width: 260px !important;
    height: 570px !important ;
  }
  .card-novedades .card-title {
    height: 96px !important;
  }

  .card-novedades .card-text {
    height: 157px;
  }
}
.boton-ver-mas2 {
  width: 135px;
  height: 49px;
  background: linear-gradient(275.16deg, #bb8e11 5%, #f2ca5b 49.17%, #b28405 94.82%);
  border-radius: 12px !important;
  color: #0b0e11 !important;
}
@media (max-width: 500px) {
  .boton-ver-mas2 {
    width: 127px;
    height: 46px;
  }
}

/*Formulario contacto*/
.form-contactanos-escritorio .form-control {
  color: white;
  height: 50px;
  background: rgba(255, 255, 255, 0.059);
  mix-blend-mode: normal;
  backdrop-filter: blur(30px);
  border: none;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .form-contactanos-escritorio .form-control {
    color: white;
    height: 50px;
    background: rgba(255, 255, 255, 0.059);
    mix-blend-mode: normal;
    backdrop-filter: blur(30px);
    border: none;
    border-radius: 10px;
  }
}

@media (max-width: 500px) {
  .form-contactanos-escritorio .form-control {
    color: white;
    height: 50px;
    background: rgba(255, 255, 255, 0.059);
    mix-blend-mode: normal;
    backdrop-filter: blur(30px);
    border: none;
    border-radius: 10px;
  }
}

@media (max-width: 375px) {
  .form-contactanos-escritorio .form-control {
    color: white;
    height: 50px;
    background: rgba(255, 255, 255, 0.059);
    mix-blend-mode: normal;
    backdrop-filter: blur(30px);
    border: none;
    border-radius: 10px;
  }
}

.input-mensaje {
  height: 140px !important;
  resize: none;
}

/* @media (max-width: 1024px) {
  .input-mensaje {
    resize: none;
  }
}

@media (max-width: 768px) {
  .input-mensaje {
    resize: none;
  }
}

@media (max-width: 500px) {
  .input-mensaje {
    resize: none;
  }
} */

/* Comentarios*/
.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 30% !important;
  position: relative;
  transition-property: transform;
}

.card-comentario {
  width: 500px;
  height: 500px !important;
}

.foto-comentario {
  max-width: 100px;
  max-height: 100px;
}

@media (max-width: 500px) {
  .card-comentario {
    width: 400px;
    height: 500px !important;
  }
}

/*Preguntas frecuentes*/
.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.375rem;
  --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1.25rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27var%28--bs-body-color%29%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e);
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%230c63e4%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e);
  --bs-accordion-btn-focus-border-color: #f8f8f8 !important;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem #fffcfc !important;
  --bs-accordion-body-padding-x: 2rem !important;
  --bs-accordion-body-padding-y: 2rem !important;
  --bs-accordion-active-color: #fafafa !important;
  --bs-accordion-active-bg: #e7f1ff !important;
}
.accordion-button:not(.collapsed) {
  color: white;
  background-color: #fcfcfe;
  box-shadow: inset 0 -1px 0 rgba(255, 253, 253, 0.13);
}

.accordion-item {
  background-color: #f7f7f7;
  border: 1px solid rgba(255, 255, 255, 0.8);
}

.accordion-button::after {
  flex-shrink: 0;
  margin-left: auto;
  background-image: url(./assets/img/mas.svg) !important;
  background-repeat: no-repeat;
  background-size: 1.25rem !important;
  transition: transform 0.2s ease-in-out;
}

.accordion-button:not(.collapsed)::after {
  background-image: url(./assets/img/cruz.svg) !important;
  transform: rotate(-180deg);
}

.accordion-button {
  color: rgb(245, 245, 245);
  background-color: #fcfbfb;
  height: 200px;
}

.accordion-button:focus {
  z-index: 3;
  outline: 0;
}

.accordion-button:not(.collapsed) {
  color: rgb(5, 5, 5) !important;
  background-color: #ffffff !important;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

@media (max-width: 500px) {
  .accordion-button {
    color: rgb(245, 245, 245);
    background-color: #141414;
  }
  .accordion-button:focus {
    z-index: 3;
    outline: 0;
  }
}

@media (max-width: 375px) {
  .accordion-button {
    width: 350px;
    color: rgb(245, 245, 245);
    background-color: #141414;
  }
  .accordion-button:focus {
    z-index: 3;
    outline: 0;
  }
}

.margin-acordeon {
  margin-top: -200px !important;
  z-index: 2;
}

@media (max-width: 1024px) {
  .margin-acordeon {
    margin-top: -120px !important;
    z-index: 2;
  }
}

@media (max-width: 768px) {
  .margin-acordeon {
    margin-top: -100px !important;
    z-index: 2;
  }
}

@media (max-width: 768px) {
  .margin-acordeon {
    margin-top: -50px !important;
    z-index: 2;
  }
}

/*MASTER CLASS*/
.tilde {
  width: 60px;
}

.tilde-un-parrafo {
  width: 25px;
}

/*FOOTER*/

@media (max-width: 500px) {
  .footer-center-texto {
    text-align: center;
  }
}

/* Navbar */ 

.nav-logo{
  max-width: 98px !important;
}

.nav-detalles .boton-contacto {
  width: 143px;
  height: 49px;
  background: linear-gradient(275.16deg, #bb8e11 5%, #f2ca5b 49.17%, #b28405 94.82%);
  border-radius: 12px !important;
  color: #0b0e11 !important;
}
@media (max-width: 768px) {
  .nav-detalles .boton-contacto {
    width: 102px;
  height: 38px;
  }
}

.cursor .swiper-button-next {
  color: #b28405;
}

.cursor .swiper-button-prev {
  color: #b28405;
}
.cursor .swiper-pagination-bullet-active{
  background: #b28405;
}
.enatips .swiper-button-next {
  color: #b28405;
}

.enatips .swiper-button-prev {
  color: #b28405;
}


.cursor .carousel-indicators [data-bs-target]{
  background-color: #FFFFFF !important;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  opacity: 1;
}
.cursor .carousel-indicators .active {
  background-color: #EE5F00 !important;
}

.pagination .page-link {
  color: #b28405;
  border-color: #b28405;
}

.pagination .page-item.active .page-link {
  background-color: #b28405;
  border-color: #b28405;

}

/*Detalles*/
.banner-detalle{
  width: 100%;
}


